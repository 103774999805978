<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */
import { defineAsyncComponent } from "vue";
import { useEventListener } from "@/composables/utils/events";
import { useMainStore } from "@/store/main";
import { useRoute, useRouter } from "vue-router";


import ModalX from "../SVG/actions/ModalX.vue";
import Button from "../Button.vue";

withDefaults(
  defineProps<{
    hasClose?: boolean;
    title?: string;
    maxWidth?: string;
    iconPath?: string;
    hasBackButton?: boolean;
    padding?: string;
    isCalendar?: boolean;
    isHeightFull?: boolean;
    expandToFullScreen?: boolean;
    hideBackDrop?: boolean;
  }>(),
  {
    title: "Popup",
    hasClose: true,
    maxWidth: "560px",
    hasBackButton: false,
    padding: "10px",
  }
);

const emit = defineEmits(["close", "back"]);

const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();

const BackDrop = defineAsyncComponent(() => import("../BackDrop.vue"));

const onClose = () => {
  emit("close");
  const hasQuery = route.query.hasOwnProperty(mainStore.autoOpenModalQueryKey);

  if (hasQuery) {
    router.replace({
      query: {
        ...route.query,
        [mainStore.autoOpenModalQueryKey]: undefined,
      },
    });

    setTimeout(() => {
      mainStore.hasIgnorableQuery = false;
    }, 800);
  }
};

const keyPressHandler = (e: KeyboardEvent) => {
  const isEsc = e.code === "Escape";
  if (isEsc) {
    onClose();
  }
};

const clickOutsideHandler = {
  detectIframe: false,
  middleware(e: Event) {},
  handler: (e: Event) => {
    onClose();
  },
};
useEventListener(document, "keydown", keyPressHandler);
</script>

<template>
  <section
    :class="[
      'base-modal',
      { 'base-modal--calendar': isCalendar },
      { 'base-modal--h-full': isHeightFull },
      { 'base-modal--expand-full-screen': expandToFullScreen },
    ]"
  >
    <div class="base-modal--wrapper" v-click-outside="clickOutsideHandler">
      <div class="base-modal__content">
        <div class="base-modal__content-head d-flex j-btw al-center w-full pos-rel">
          <div class="d-flex al-center head-in">
            <slot name="head">
              <h2 class="base-modal__content-title p-18" v-if="title && !hasBackButton">
                {{ $t(title?.toLowerCase()) }}
              </h2>
              <Button
                v-if="hasBackButton"
                :isBack="true"
                :btnType="8"
                @click="$emit('back')"
              />
            </slot>
          </div>
          <div class="base-modal--close pos-rel pointer" v-if="hasClose" @click="onClose">
            <ModalX />
          </div>
        </div>

        <slot />
      </div>
    </div>

    <BackDrop v-if="!hideBackDrop" @click="onClose" />
  </section>
</template>

<style lang="scss">
.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  &__content {
    // padding: 22px;
    padding: 32px;
    overflow-y: auto;
    scrollbar-gutter: stable;
    padding-right: 24px;

    @include maxq(mobile-sm) {
      padding: 13px;
    }

    &-head {
      margin-bottom: 40px;

      @include maxq(desktop-2k) {
        margin-bottom: 32px;
      }
      @include maxq(desktop-lg) {
        margin-bottom: 24px;
      }
    }

    &-icon {
      margin-right: 8px;
    }

    &-title {
      // font-size: 46px;

      // @include maxq(desktop-2k) {
      //   font-size: 28px;
      // }

      // @include maxq(desktop-lg) {
      //   font-size: 20px;
      //   line-height: 20px;
      // }
      color: $modalDark;
      // @include weight(400);
    }

    @include scrollbar;
  }

  &--wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    max-height: 80%;
    // padding: 10px;
    margin: 0 16px;
    z-index: 7;
    scrollbar-gutter: auto;
    width: 100%;
    overflow: hidden;
    align-self: center;

    max-width: 1200px;

    @include maxq(desktop-2k) {
      max-width: 800px;
    }

    @include maxq(desktop-lg) {
      max-width: v-bind(maxWidth);
    }
  }

  &--close {
    padding: 8px;
    top: 0;
    right: 0;
    // border: 1px solid rgba($black, 0.2);
    @include easeInOut(400, all);

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 1px solid rgba($black, 0.2);
      @include easeInOut(400, all);
    }

    @include hover {
      &::after {
        transform: scale(1.2);
      }
    }
  }

  &--calendar {
    .base-modal--wrapper {
      max-width: 1400px;

      @include maxq(desktop-2k) {
        max-width: 1000px;
      }

      @include maxq(desktop-lg) {
        max-width: v-bind(maxWidth);
      }
    }
  }

  &--h-full {
    .base-modal--wrapper {
      height: 80%;
    }

    .base-modal__content {
      height: 100%;
    }
  }

  &--expand-full-screen {
    @include maxq(mobile) {
      .base-modal {
        &--wrapper {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          margin: 0;
        }
      }
    }
  }

  &--flats-gallery {
    .base-modal--wrapper {
      margin: 0;
      max-width: unset;
      max-height: unset;
      height: 100%;
    }
    .base-modal__content {
      overflow: hidden;
      @include maxq(mobile) {
        height: 100%;
        .swiper {
          margin-top: 10%;
        }
      }
      @media (max-height: 800px) {
        height: 100%;

        .swiper {
          height: 57%;
        }
      }
      &-title {
        display: none;
      }
      &-head {
        @include maxq(desktop) {
          margin-bottom: 0;
        }
      }
      .gumbati-slider {
        @include maxq(mobile) {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        @media (max-height: 800px) {
          height: 100%;
        }
        &__inner {
          @include maxq(mobile) {
            margin-bottom: 61px;
          }

          @media (max-height: 800px) {
            height: 100%;
          }
        }
        &__prev,
        &__next {
          @include maxq(mobile-sm) {
            display: none;
          }
        }
      }
    }
  }

  &.booking-modal {
    .base-modal--wrapper {
      overflow: visible;
    }

    .base-modal__content {
      overflow: visible;
    }
  }
}
</style>
