<template>
  <div class="d-flex pointer j-center al-center icon modal-x-icon">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" fill="#222222"/>
      <path d="M20.5 11.5L11.5 20.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.5 20.5L11.5 11.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<style lang="scss">
.modal-x-icon {
  --w: 64px;

  svg {
    width: var(--w);
    height: var(--w);
  }


  @include maxq(desktop-2k) { 
    --w: 46px;
  }

  @include maxq(desktop-lg) { 
    --w: 32px;
   }
}
</style>